.App {
  text-align: center;
  background-color: #FFFDED;
  height: 100%;
  max-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: #004870;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 30px;
  pointer-events: none;
  padding: 0 20px;
}

.App-header {
  background-color: #FF6B6B;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: right;
}

.App-footer {
  background-color: #004870;
  min-height: 40px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
}

.icons {
  padding: 2px;
  margin-top: 5px;
}

.content {
  overflow-y: hidden; 
  padding: 10px; 
  height: 100%;
  display: 'flex'; flex-direction: 'column';
}

@media (max-width: 600px) {
  .content {
    overflow-y: scroll;
    height: auto; 
  }
}

.App-link {
  color: #FF6B6B;
}

.left-line {
  margin-left: 30px;
  border-left: 5px solid #FF6B6B;
}

.modal-content {
  color: #004870;
}
